
  import Vue, { PropType } from 'vue';
  import { InfoTooltip } from '@/components/shared/info-tooltip';

  export default Vue.component('cfp-form-input-wrapper', {
    name: 'cfp-form-input-wrapper',

    components: {
      'info-tooltip': InfoTooltip,
    }, // components

    props: {
      id: {
        type: String as PropType<string>,
        required: true,
      },

      name: {
        type: String as PropType<string>,
        required: true,
      },

      hasCharacterCount: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      count: {
        type: Number as PropType<number>,
        default: 0,
      },

      rules: {
        type: Array as PropType<string[]>,
        default: () => ([]),
      },

      hideLabel: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      formLabel: {
        type: String as PropType<string>,
        required: true,
      },

      tooltip: {
        type: String as PropType<string>,
        default: '',
      },

      fieldset: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      inline: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      loading: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      prefixIcon: {
        type: String as PropType<string>,
        default: null,
      },
    }, // props

    computed: {
      characterLimit(): number {
        return +(this.rules
          .find(rule => rule.startsWith('max'))?.split(':')?.[1] || 0);
      },
    },
  });
