
  import Vue from 'vue';
  import { nanoid } from 'nanoid';

  export default Vue.extend({
    name: 'pm-discard-changes-modal',

    data: () => ({
      id: nanoid(),
    }),

    computed: {
      isOpen: {
        get(): boolean {
          const {
            attemptedRoute,
            isAttemptingRouteChange,
          } = this.$discardChanges;
          return attemptedRoute !== null || isAttemptingRouteChange;
        },
        set(isOpen: boolean) {
          if (!isOpen) {
            this.$discardChanges.attemptedRoute = null;
            this.$discardChanges.isAttemptingRouteChange = false;
            window.onbeforeunload = null;
          } else {
            window.onbeforeunload = () => true;
          }
        },
      },
    },

    methods: {
      onContinue() {
        this.$discardChanges.isViewDirty = false;
        const { attemptedRoute } = this.$discardChanges;
        if (attemptedRoute) {
          this.$router.push({ path: attemptedRoute.path });
        }

        this.onClearRoute();
      },

      onClearRoute() {
        this.isOpen = false;
      },
    },
  });
