
  import Vue from 'vue';
  import { ProductIcon } from '@/components/product-icon';

  interface Circle {
    angle: number;
    radius: number;
    startingPosition: { x: number; y: number };
  }

  export default Vue.component('cfp-app-spinner', {
    components: {
      'cfp-product-icon': ProductIcon,
    },

    props: {
      product: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        width: 750,
        scale: 0.75,
        angles: [90, 215],
      };
    },

    computed: {
      range(): number[] {
        return [(this.halfWidth * this.scale) + 40, this.halfWidth - 10];
      },
      circles(): Circle[] {
        const { range, startingPosition, angles } = this;
        return angles.map((angle, index) => {
          const radius = range[index];
          return {
            angle,
            radius,
            startingPosition: startingPosition(radius, angle),
          };
        });
      },
      halfWidth(): number {
        return this.width / 2;
      },
      pi(): number {
        return Math.PI;
      },
    },

    methods: {
      startingPosition(radius: number, angle: number): { x: number; y: number } {
        const radians = (this.pi / 180) * (angle + 90);
        return {
          x: this.halfWidth + (radius * Math.sin(radians)),
          y: this.halfWidth + (radius * Math.cos(radians)),
        };
      },
    },

  });
