
  import Vue, { PropType } from 'vue';

  interface Props {
    align: string;
    cancelRoute: string;
    disabled: boolean;
    submitButtonText: string;
  }

  export default Vue.component<Props>('cfp-form-buttons', {
    functional: true,

    props: {
      align: {
        type: String as PropType<string>,
        default: 'between',
      },
      cancelRoute: {
        type: String as PropType<string>,
        required: true,
      },
      disabled: {
        type: Boolean as PropType<boolean>,
        default: false,
      },
      submitButtonText: {
        type: String as PropType<string>,
        default: 'generic.save',
      },
    },
  });
