
  import Vue, { PropType } from 'vue';
  import { Intent, ButtonIntent } from '@/enums/Intent';
  import { i18n } from '@/i18n';

  export default Vue.extend({
    name: 'confirmation-modal',
    model: {
      prop: 'is-open',
      event: 'is-open',
    }, // model
    props: {
      isOpen: {
        required: true,
        type: Boolean as PropType<boolean>,
      },
      title: {
        default: i18n.t('modals.confirm.title').toString(),
        type: String as PropType<string>,
      },
      message: {
        default: i18n.t('modals.confirm.message').toString(),
        type: String as PropType<string>,
      },
      buttonText: {
        default: i18n.t('modals.confirm.buttonText').toString(),
        type: String as PropType<string>,
      },
      intent: {
        default: Intent.error,
        type: String as PropType<keyof typeof Intent>,
      },
    }, // props
    computed: {
      buttonIntent() {
        switch (this.intent) {
          case Intent.error:
            return ButtonIntent.danger;
          case Intent.info:
            return ButtonIntent.primary;
          default:
            return ButtonIntent.tertiary;
        }
      },
    }, // computed
    methods: {
      exitModal() {
        this.$emit('is-open', false);
      },
      onCancel() {
        this.$emit('on-cancel');
        this.exitModal();
      },
      onConfirm() {
        this.$emit('on-confirm');
        this.exitModal();
      },
    }, // methods
  });
