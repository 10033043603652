
  import { debounce } from 'lodash-es';
  import { Page, QueryParams } from '@/interfaces/QueryParams';
  import { Nullable } from '@cofense-ui/utils';
  import Vue, { PropType } from 'vue';
  import { Response } from '@/interfaces/Responses';
  import FormSelectInput from './FormSelectInput.vue';

  type SelectValue = string | string[] | Record<string, any> | Record<string, any>[];

  export default Vue.component('cfp-form-fetch-select-input', {
    name: 'cfp-form-fetch-select-input',

    components: {
      'cfp-form-select-input': FormSelectInput,
    },

    inheritAttrs: false,

    props: {
      value: {
        type: [Array, String, Object] as PropType<Nullable<SelectValue>>,
        default: '',
      },

      endpoint: {
        type: String as PropType<string>,
        required: true,
      },
      params: {
        type: Object as PropType<QueryParams>,
        default: () => ({}),
      },

      reduceResponse: {
        type: Function,
        default: (value: any) => value,
      },

      hasInfiniteScroll: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      usesLoadingSkeleton: {
        type: Boolean as PropType<boolean>,
        default: true,
      },

      selectAllName: {
        type: String as PropType<Nullable<string>>,
        default: null,
      },
    }, // props

    data() {
      return {
        observer: {} as IntersectionObserver,
        suggestionsUl: null as Nullable<Element>,
        scrollTop: 0,
        localResponse: [] as Nullable<Response<any>[]>,
        pageInfo: null as Nullable<Record<string, any>>,
        localParams: { ...this.params } as QueryParams,
        isFirstFetch: true,
        isSearching: false,
      };
    },

    computed: {
      localValue: {
        get(): Nullable<SelectValue> {
          return this.value;
        },
        set(value: SelectValue): void {
          this.$emit('input', value);
        },
      },
      hasNextPage(): boolean {
        return this.pageInfo?.records && this.pageInfo?.records !== 0
          && (this.pageInfo?.number < (this.pageInfo.records / this.pageInfo.size));
      },
      canLoadMoreOptions(): boolean {
        return this.hasInfiniteScroll && this.hasNextPage;
      },
    }, // computed

    watch: {
      localResponse: {
        async handler() {
          if (this.canLoadMoreOptions || !this.hasNextPage) {
            if (Object.getPrototypeOf(this.observer) === Object.prototype) {
              this.observer = new IntersectionObserver(this.infiniteScroll);
            } else {
              await this.connectObserver();
              if (this.scrollTop !== 0 && this.suggestionsUl) {
                this.suggestionsUl.scrollTop = this.scrollTop;
              }
            }
          }
        },
        deep: true,
        immediate: true,
      },
    }, // watch

    methods: {
      onSuccess(data: { data: Response<any>, meta: { page: Page}}): void {
        if (this.selectAllName) {
          const selectAllOption: Response<any> = { type: String, name: this.selectAllName, id: '*' };
          this.localResponse?.push(selectAllOption);
        }
        this.localResponse?.push(...this.reduceResponse(data.data));
        this.pageInfo = data?.meta?.page;
        this.$emit('on-success', data);
        this.isFirstFetch = false;
        this.isSearching = false;
      },
      async onOpen(): Promise<void> {
        if (this.canLoadMoreOptions) {
          this.connectObserver();
        }
      },
      async connectObserver(): Promise<void> {
        await this.$nextTick();
        if (this.$refs.spinner) {
          this.observer.observe(this.$refs.spinner as Element);
        }
      },
      infiniteScroll([{
        isIntersecting,
        target,
      }]: IntersectionObserverEntry[]): void {
        if (isIntersecting && this.localResponse && this.localResponse.length > 0) {
          this.suggestionsUl = (target as HTMLElement).offsetParent;
          if (this.suggestionsUl) {
            this.scrollTop = this.suggestionsUl.scrollTop;
          }
          this.increasePageNumber();
          this.observer.disconnect();
        }
      },
      onClose() {
        if (this.canLoadMoreOptions) {
          this.observer.disconnect();
        }
      },
      onSearch: debounce(async function debouncedSearch(this: any, filter: string) {
        if (this.hasInfiniteScroll) {
          this.localResponse = [];
          if (this.localParams?.page?.number) {
            this.isSearching = true;
            const tempParams = { ...this.localParams };
            tempParams.page.number = 1;
            if (filter && filter.trim()) {
              tempParams.filter = { name: filter };
            } else {
              tempParams.filter = undefined;
            }
            this.localParams = { ...tempParams };
          }
        }
      }, 300),
      increasePageNumber() {
        const tempParams = { ...this.localParams };
        if (tempParams?.page?.number) {
          tempParams.page.number += 1;
        }
        this.localParams = { ...tempParams };
      },
    },
  });
