
  import Vue, { PropType } from 'vue';
  import { Nullable } from '@cofense-ui/utils';

  type SelectValue = string | string[] | Record<string, any> | Record<string, any>[];
  type SelectOption = string | Record<string, any>;

  export default Vue.component('cfp-form-select-input', {
    name: 'cfp-form-select-input',

    props: {
      id: {
        type: String as PropType<string>,
        required: true,
      },

      name: {
        type: String as PropType<string>,
        required: true,
      },

      reduce: {
        type: Function as PropType<(option: SelectOption) => SelectOption>,
        default: (option: SelectOption) => option,
      },

      value: {
        type: [Array, String, Object] as PropType<SelectValue | null>,
        default: '',
      },

      options: {
        type: Array as PropType<SelectOption[]>,
        required: true,
      },

      disabled: Boolean,

      placeholder: {
        default: '',
        type: String as PropType<string>,
      },

      errors: {
        type: Array as PropType<string[]>,
        required: true,
      },

      selectOnKeyCodes: {
        type: Array as PropType<number[]>,
        default: () => [13],
      },
    }, // props

    data: () => ({

    }),

    computed: {
      localValue: {
        get(): Nullable<SelectValue> {
          return this.value;
        },
        set(value: SelectValue): void {
          this.$emit('input', value);
        },
      },
    }, // computed

    methods: {
      async onRefocus(): Promise<void> {
        const element = this.$el.querySelector('.vs__search') as HTMLFormElement;
        await this.$nextTick();
        if (element) {
          element.focus();
        }
      },
    },
  });
