
  import { Nullable } from '@cofense-ui/utils';
  import { nanoid } from 'nanoid';
  import Vue, { PropType } from 'vue';

  interface ConfigObject {
    language: string;
    emailText: string;
    emailSubtext: Nullable<string>;
    emailButtonLabel: string;
    scenarioText: string;
    scenarioSubtext: Nullable<string>;
    scenarioButtonLabel: string;
  }

  export default Vue.component('cfp-form-repeater-input', {
    name: 'cfp-form-repeater-input',

    props: {
      defaultConfig: {
        type: Object as PropType<ConfigObject>,
        required: true,
      },
      value: {
        type: Array as PropType<ConfigObject[]>,
        default: () => ([]),
      },
      buttonLabel: {
        type: String as PropType<string>,
        required: true,
      },
    },

    data: () => ({
      localValue: [] as (ConfigObject & { id: string, isActive: boolean })[],
    }),

    watch: {
      value: {
        deep: true,
        immediate: true,
        handler(newVal, oldVal) {
          if (newVal && (newVal.length !== oldVal?.length)) {
            this.localValue = newVal
              .map((item: ConfigObject) => ({ ...item, isActive: false, id: nanoid() }));
          }
        },
      },
    },

    methods: {
      addItem() {
        this.$emit('input', [...this.value, { ...this.defaultConfig }]);
      },

      removeItem(itemId: string) {
        this.$emit(
          'input',
          this.localValue
            .filter(({ id }) => itemId !== id)
            /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
            .map(({ id, isActive, ...rest }) => ({ ...rest })),
        );
      },
    },
  });
