
  import Vue, { PropType } from 'vue';

  export default Vue.extend({
    name: 'pseudo-button',

    props: {
      tag: {
        default: 'div',
        type: String as PropType<string>,
        validator: (value: string) => ['div', 'span'].includes(value),
      },
    }, // props
  });
