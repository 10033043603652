
  import Vue, { PropType } from 'vue';
  import { Products } from '@/enums/Products';
  import { Product } from '@/interfaces/Product';
  import { OrganizationSwitcher } from '@/components/organization-switcher';
  import { Product as ProductComponent } from './Product';
  import { supportLinks } from './support-links-config';

  type ProductKeys = keyof typeof Products;

  interface ProductOption extends Omit<Product, 'type'> {
    id: string;
    path: string;
    name: ProductKeys;
  }

  export default Vue.extend({
    name: 'cfp-product-org-switcher',

    components: {
      'cfp-organization-switcher': OrganizationSwitcher,
      'cfp-product': ProductComponent,
    },

    props: {
      current: {
        required: true,
        type: String as PropType<Products>,
      },
    }, // props
    data: () => ({
      supportLinks,
    }),

    computed: {
      currentPath(): string {
        return this.products.find(({ name }) => this.current === name.toLowerCase())?.path || '/';
      },
      products(): ProductOption[] {
        return this.$store.getters['user/products']
          .filter(
            ({ name }: ProductOption) => this.$store.getters['user/currentOrg'].enabled
              || [Products.admin, Products.platform]
                .includes(Products[name.toLowerCase() as ProductKeys])
            ,
          )
          .map(
            ({
              name, id, roleNames,
            }: ProductOption) => ({
              path: `/${name.toLowerCase()}`,
              label: Products[name.toLowerCase() as ProductKeys],
              id,
              name: name.toLowerCase() as ProductKeys,
              roleNames,
            }),
          );
      },
    }, // computed
    methods: {
      checkDirtyState(onToggle: Function): void {
        if (this.$discardChanges.isViewDirty) {
          this.$discardChanges.isAttemptingRouteChange = true;
        } else {
          onToggle();
        }
      },
    },
  });
