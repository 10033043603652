
  import Vue, { VueConstructor } from 'vue';
  import { documentTitleMixin } from '@/mixins/document-title';
  import CofenseLogotype from '@koi/static-assets/logotypes/cofense-email-security-color.svg';
  import { footerLinks, profileLinks } from '@/constants/links';
  import { sidebarLinks } from '@/constants/platform-links';
  import { NavLink } from '@/interfaces/NavLink';
  import { mapNavLinks } from '@/utils/nav-links';
  import { productSvgs, ProductSvgs } from '@/constants/user/products';
  import {
    mapState,
    mapGetters,
    mapActions,
    mapMutations,
  } from 'vuex';
  import { AppSpinner } from '@/components/app-spinner';
  import { LegalAgreement } from '@/interfaces/user/LegalAgreement';
  import { Products, LegalProducts } from '@/enums/Products';
  import { ProductOrgSwitcher } from '@/components/product-org-switcher';
  import { TocDropdown } from '@/components/toc-dropdown';
  import { nanoid } from 'nanoid';
  import { api } from '@/services/api';
  import { userApi } from '@/constants/user/api';

  type ProductKeys = keyof typeof Products;

  export default Vue.extend({
    name: 'app',

    components: {
      'cofense-logotype': CofenseLogotype,
      'cfp-app-spinner': AppSpinner,
      'cfp-product-org-switcher': ProductOrgSwitcher,
      'cfp-toc-dropdown': TocDropdown,
    },

    mixins: [documentTitleMixin],

    data: () => ({
      footerLinks: mapNavLinks(footerLinks as NavLink[]),
      profileLinks: mapNavLinks(profileLinks as NavLink[]),
      svgs: { ...productSvgs } as ProductSvgs,
      isLegalAgreementChecked: false,
      legalName: LegalProducts.platform,
      isResetLegalConfirmationModalOpen: false,
      selectedOptionsValue: '',
    }), // data

    computed: {
      ...mapState('user', {
        isFetching: 'isFetching',
      }),

      ...mapGetters('user', {
        name: 'fullName',
        initials: 'initials',
        currentOrg: 'currentOrg',
        isAuthenticated: 'isUserLoggedIn',
        isToc: 'isToc',
        pendingLegal: 'pendingLegal',
      }),

      isLegalConfirmationModalOpen(): boolean {
        return (
          !!this.pendingLegal
          && this.pendingLegal.some((legal: LegalAgreement) => legal.productId === this.legalName)
        );
      },

      resetLegalText(): Record<string, string> {
        if (this.selectedOptionsValue === 'reset-toc') {
          return {
            title: this.$t('modals.resetLegal.org.title').toString(),
            message: this.$t('modals.resetLegal.org.message', { name: this.currentOrg.name }).toString(),
          };
        }
        if (this.selectedOptionsValue === 'reset-all-toc') {
          return {
            title: this.$t('modals.resetLegal.all.title').toString(),
            message: this.$t('modals.resetLegal.all.message').toString(),
          };
        }
        return {};
      },

      logo(): VueConstructor<Vue> {
        const logotype = this.svgs[this.productName]?.logotype || { ...CofenseLogotype };
        return { ...logotype } as VueConstructor<Vue>;
      },

      productName(): ProductKeys {
        const productName = this.$route?.matched
          .find(record => record.name)?.name?.split('.')[0] as ProductKeys || 'platform';
        return Products[productName] ? productName : 'platform';
      },

      sidebarLinks(): NavLink[] {
        return mapNavLinks(sidebarLinks as NavLink[]);
      },
    }, // computed

    watch: {
      productName: {
        handler(currentProduct) {
          this.$store.commit('user/setProduct', currentProduct);
          if (this.isAuthenticated) {
            this.$store.dispatch('user/fetchUser');
          }
        },
        immediate: true,
      },
    }, // watch
    methods: {
      openLegalResetConfirmationModal(value: string) {
        this.isResetLegalConfirmationModalOpen = true;
        this.selectedOptionsValue = value;
      },
      async resetLegal(): Promise<void> {
        if (this.selectedOptionsValue === 'reset-toc') {
          await api.update(userApi.resetOrgLegalTerms(this.currentOrg.id));
        } else if (this.selectedOptionsValue === 'reset-all-toc') {
          await api.update(userApi.resetAllLegalTerms);
        }
        this.$koi.toast({
          id: nanoid(),
          title: this.$t('generic.success').toString(),
          message: this.$t('toasts.updateShort', { name: this.$t('generic.legal') }).toString(),
          intent: 'success',
          duration: 7500,
        });
      },
      closeModalAndLogOut() {
        this.setPendingLegal([]);
        /* eslint-disable-next-line @typescript-eslint/no-empty-function */
        this.$router.push('/logout').catch(() => {});
      },
      async acceptLegalAgreement() {
        const legalAgreement = this.pendingLegal.find(
          (legal: LegalAgreement) => legal.productId === this.legalName,
        );
        await this.updateLegal(legalAgreement.termsAndConditionsCaptureId);
        this.$router.go(0);
      },
      ...mapActions('user', {
        updateLegal: 'updateLegal',
      }),
      ...mapMutations('user', {
        setPendingLegal: 'setPendingLegal',
      }),
    },
  });
