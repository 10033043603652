
  import Vue, { PropType } from 'vue';
  import { PseudoButton } from '@/components/shared/pseudo-button';

  export default Vue.extend({
    name: 'info-tooltip',

    components: {
      'pseudo-button': PseudoButton,
    }, // components

    props: {
      content: {
        required: true,
        type: String as PropType<string>,
      },

      placement: {
        type: String as PropType<string>,
        default: () => 'top',
        validator: (value: string) => (/(^(top|right|bottom|left)(-(start|end)+)?$)/g).test(value),
      },

      size: {
        type: String as PropType<string>,
        default: () => 'form-sm',
        validator: (value: string) => [
          'button-sm',
          'button-md',
          'button-lg',
          'form-sm',
          'form-md',
          'form-lg',
          'text-sm',
          'text-md',
          'title-sm',
          'title-md',
          'headline-xxs',
          'headline-xs',
          'headline-sm',
          'headline-md',
          'headline-lg',
          'headline-xl',
        ].includes(value),
      },
    }, // props
  });
