
  import Vue from 'vue';
  import { mapGetters } from 'vuex';
  import { Roles } from '@/enums/Roles';
  import { DropdownOptions, orgOptionsConst } from './dropdown-options';

  export default Vue.extend({
    name: 'toc-dropdown',

    data: () => ({
      orgOptions: [] as DropdownOptions[],
      isAdminApp: window.location?.pathname?.startsWith('/admin'),
    }),

    computed: {
      linkType() {
        return this.isAdminApp ? 'router-link' : 'a';
      },
      ...mapGetters('user', {
        currentOrg: 'currentOrg',
        globalProduct: 'globalProduct',
      }),
    },

    watch: {
      currentOrg: {
        immediate: true,
        handler(newVal) {
          this.orgOptions = [...orgOptionsConst(newVal.id)] as DropdownOptions[];
        },
      },
    },

    mounted() {
      // TODO: Remove entire if block. This is a feature flag for https://jira.corp.phishme.com/browse/MH-3424
      if (this.currentOrg?.id !== 'de454f3d-32d1-4caa-bd55-234b671d1c7b') {
        this.orgOptions.splice(1, 1);
      }
      // TODO: Remove down to here
      if (!this.globalProduct?.roleNames?.includes(Roles.reporterStatsReader)) {
        this.orgOptions = this.orgOptions?.filter(
          (orgOption: DropdownOptions) => orgOption.label !== 'graphReport',
        );
      }
    },

    methods: {
      getLinkProps(to: string): Record<string, string> {
        if (this.isAdminApp && to.startsWith('/admin')) {
          return { to };
        }
        return { href: to };
      },
    },
  });
