
  import Vue from 'vue';
  import { mapGetters, mapActions } from 'vuex';
  import { Nullable } from '@cofense-ui/utils';
  import { userApi } from '@/constants/user/api';
  import { Organization } from '@/interfaces/user/Organization';
  import { QueryParams } from '@/interfaces/QueryParams';
  import { Products } from '@/enums/Products';
  import { Roles } from '@/enums/Roles';
  import { Product } from '@/interfaces/user/Product';
  import { FormInputWrapper, FormFetchSelectInput } from '@/components/form-inputs';

  export default Vue.extend({
    name: 'cfp-organization-switcher',

    components: {
      'cfp-form-input-wrapper': FormInputWrapper,
      'cfp-form-fetch-select-input': FormFetchSelectInput,
    },

    data: () => ({
      localOrgId: null as Nullable<string>,
      payload: null as Nullable<Record<string, any>>,
      filter: null as Nullable<string>,
      searchOrgs: `${userApi.organizations}?include=products`,
      size: 25,
      params: {
        page: { size: 25, number: 1 },
        sort: 'name',
      } as QueryParams,
    }),

    computed: {
      ...mapGetters('user', {
        userId: 'userId',
        orgId: 'currentOrgId',
        currentOrg: 'currentOrg',
        isToc: 'isToc',
      }),
      currentOrgId: {
        get(): string | Pick<Organization, 'id' | 'name'> {
          return this.localOrgId || { id: this.orgId, name: this.currentOrg.name };
        },
        set(value: string) {
          this.localOrgId = value;
          this.payload = {
            id: `user-id-${this.userId}`,
            type: 'preferences',
            relationshipNames: ['currentOrganization'],
            currentOrganization: { type: 'organization', id: value },
          };
        },
      },
      preferencesEndpoint(): string {
        return `${userApi.preferences}/user-id-${this.userId}`;
      },
      hasScrollPermission(): boolean {
        const validatorProduct: Product = this.currentOrg.products.find(
          (product: Product) => product.name === Products.validator,
        );
        const reporterProduct: Product = this.currentOrg.products.find(
          (product: Product) => product.name === Products.reporter,
        );
        return (
          this.isToc
          || validatorProduct?.roleNames.includes(Roles.validatorAdmin)
          || reporterProduct?.roleNames.includes(Roles.reporterAdmin)
        );
      },
    }, // computed

    methods: {
      ...mapActions({
        fetchUser: 'user/fetchUser',
      }),
      async onSuccess() {
        if (this.$route.name !== 'platform.dashboard') {
          await this.$router.push({ name: 'platform.dashboard' });
        }
        this.$router.go(0);
      },
    },
  });
