
  import Vue, { PropType } from 'vue';
  import { RawLocation } from 'vue-router';
  import reporterRed from '@koi/static-assets/logomarks/reporter-red.svg';

  export default Vue.extend({
    name: 'not-found',

    components: {
      'pr-red-logo': reporterRed,
    },

    props: {
      to: {
        default: '/',
        type: [String, Object] as PropType<RawLocation | string>,
      },

      title: {
        type: String as PropType<string>,
        default: undefined,
      },
      badge: {
        type: String as PropType<string>,
        default: undefined,
      },
      button: {
        type: String as PropType<string>,
        default: undefined,
      },

    },
    data() {
      return {
        localButton: this.button ? this.button : this.$t('goHome'),
        localBadge: this.badge ? this.badge : this.$t('notFound'),
        localTitle: this.title ? this.title : this.$t('notFound'),
      };
    },
  });
