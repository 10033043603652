import { render, staticRenderFns } from "./ProductOrgSwitcher.vue?vue&type=template&id=82cb4308&"
import script from "./ProductOrgSwitcher.vue?vue&type=script&lang=ts&"
export * from "./ProductOrgSwitcher.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./translations.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fubuntu%2Fworkspace%2Fm_ui_platform-ui_release_1.2-RC1%2Fsrc%2Fcomponents%2Fproduct-org-switcher%2FProductOrgSwitcher.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports